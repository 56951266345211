
import CloudFun, { computed, defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
    // CheckBoxList
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]

    const gridOptions: GridOptions = {
      id: 'manufacturerEstimatedPurchase',
      title: '廠商預計進貨明細',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canDelete: false,
      canUpdate: false,
      canCreate: false,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'ManufacturerShortName', title: '廠商簡稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ManufacturerCode', title: '廠商代號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'PurchaseDate', title: '採購日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : '' },
        { field: 'PurchaseNumber', title: '採購單編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'EstimatedDate', title: '預交日期', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ProductNumber', title: '品號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ProductName', title: '品名', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ProductSpecifications', title: '規格', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'PurchaseQuantity', title: '採購數量', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Uint', title: '單位', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'BacklogQuantity', title: '未交數量', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'OrderStatus', title: '結案狀態', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderStatus).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch('manufacturerEstimatedPurchase/query', params)
          : undefined,
        queryAll: model ? () => model.dispatch('manufacturerEstimatedPurchase/query') : undefined,
        save: model
          ? (params) => model.dispatch('manufacturerEstimatedPurchase/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'ManufacturerShortName',
          title: '廠商簡稱',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ManufacturerCode',
          title: '廠商代號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'PurchaseDate',
          title: '採購日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: true } }
        },
        {
          field: 'PurchaseNumber',
          title: '採購單編號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'EstimatedDate',
          title: '預交日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: true } }
        },
        {
          field: 'ProductNumber',
          title: '品號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ProductName',
          title: '品名',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ProductSpecifications',
          title: '規格',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'PurchaseQuantity',
          title: '採購數量',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'Uint',
          title: '單位',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'BacklogQuantity',
          title: '未交數量',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'ContainerType',
          title: '櫃型',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ContainerNumber',
          title: '櫃號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Time',
          title: '時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: true } }
        },
        {
          field: 'OrderStatus',
          title: '結案狀態',
          span: 12,
          itemRender: { name: "$select", props: { disabled: true }, options: model ? Object.values(model.enums.OrderStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
      ],
      // rules: {
      //   Name: [{ type: 'string', required: true }]
      // }
    }

    return {
      grid,
      gridOptions,
      formOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
